// package: auth_svc
// file: auth_svc.proto

var auth_svc_pb = require("./auth_svc_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;
var AuthSvc = function () {
  function AuthSvc() {}
  AuthSvc.serviceName = "auth_svc.AuthSvc";
  return AuthSvc;
}();
AuthSvc.WalletSignInStart = {
  methodName: "WalletSignInStart",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.WalletSignInStartRequest,
  responseType: auth_svc_pb.WalletSignInStartResponse
};
AuthSvc.WalletSignInFinalize = {
  methodName: "WalletSignInFinalize",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.WalletSignInFinalizeRequest,
  responseType: auth_svc_pb.WalletSignInFinalizeResponse
};
AuthSvc.SessionCheck = {
  methodName: "SessionCheck",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.SessionCheckRequest,
  responseType: auth_svc_pb.SessionCheckResponse
};
AuthSvc.OauthGoogleSignIn = {
  methodName: "OauthGoogleSignIn",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.OauthGoogleSignInRequest,
  responseType: auth_svc_pb.OauthGoogleSignInResponse
};
AuthSvc.OauthGoogleCallback = {
  methodName: "OauthGoogleCallback",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.OauthGoogleCallbackRequest,
  responseType: auth_svc_pb.OauthGoogleCallbackResponse
};
AuthSvc.HealthCheck = {
  methodName: "HealthCheck",
  service: AuthSvc,
  requestStream: false,
  responseStream: false,
  requestType: auth_svc_pb.HealthCheckRequest,
  responseType: auth_svc_pb.HealthCheckResponse
};
exports.AuthSvc = AuthSvc;
function AuthSvcClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}
AuthSvcClient.prototype.walletSignInStart = function walletSignInStart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.WalletSignInStart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
AuthSvcClient.prototype.walletSignInFinalize = function walletSignInFinalize(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.WalletSignInFinalize, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
AuthSvcClient.prototype.sessionCheck = function sessionCheck(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.SessionCheck, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
AuthSvcClient.prototype.oauthGoogleSignIn = function oauthGoogleSignIn(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.OauthGoogleSignIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
AuthSvcClient.prototype.oauthGoogleCallback = function oauthGoogleCallback(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.OauthGoogleCallback, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
AuthSvcClient.prototype.healthCheck = function healthCheck(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthSvc.HealthCheck, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function onEnd(response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function cancel() {
      callback = null;
      client.close();
    }
  };
};
exports.AuthSvcClient = AuthSvcClient;