// source: danielvladco/protobuf/graphql.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
  return this;
}.call(null) || Function('return this')();
var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Field', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Oneof', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Rpc', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Svc', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Type', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.Upstream', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.field', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.oneof', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.rpc', null, global);
goog.exportSymbol('proto.danielvladco.protobuf.graphql.svc', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.danielvladco.protobuf.graphql.Oneof = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.danielvladco.protobuf.graphql.Oneof, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.danielvladco.protobuf.graphql.Oneof.displayName = 'proto.danielvladco.protobuf.graphql.Oneof';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.danielvladco.protobuf.graphql.Field = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.danielvladco.protobuf.graphql.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.danielvladco.protobuf.graphql.Field.displayName = 'proto.danielvladco.protobuf.graphql.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.danielvladco.protobuf.graphql.Rpc = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.danielvladco.protobuf.graphql.Rpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.danielvladco.protobuf.graphql.Rpc.displayName = 'proto.danielvladco.protobuf.graphql.Rpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.danielvladco.protobuf.graphql.Svc = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.danielvladco.protobuf.graphql.Svc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.danielvladco.protobuf.graphql.Svc.displayName = 'proto.danielvladco.protobuf.graphql.Svc';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.danielvladco.protobuf.graphql.Oneof.prototype.toObject = function (opt_includeInstance) {
    return proto.danielvladco.protobuf.graphql.Oneof.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.danielvladco.protobuf.graphql.Oneof} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.danielvladco.protobuf.graphql.Oneof.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ignore: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
        name: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.danielvladco.protobuf.graphql.Oneof}
 */
proto.danielvladco.protobuf.graphql.Oneof.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.danielvladco.protobuf.graphql.Oneof();
  return proto.danielvladco.protobuf.graphql.Oneof.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.danielvladco.protobuf.graphql.Oneof} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.danielvladco.protobuf.graphql.Oneof}
 */
proto.danielvladco.protobuf.graphql.Oneof.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 4:
        var value = /** @type {boolean} */reader.readBool();
        msg.setIgnore(value);
        break;
      case 5:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.danielvladco.protobuf.graphql.Oneof.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.danielvladco.protobuf.graphql.Oneof} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.danielvladco.protobuf.graphql.Oneof.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeBool(4, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeString(5, f);
  }
};

/**
 * optional bool ignore = 4;
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.getIgnore = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 4, false);
};

/**
 * @param {boolean} value
 * @return {!proto.danielvladco.protobuf.graphql.Oneof} returns this
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.setIgnore = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Oneof} returns this
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.clearIgnore = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.hasIgnore = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string name = 5;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 5, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Oneof} returns this
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.setName = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Oneof} returns this
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.clearName = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Oneof.prototype.hasName = function () {
  return jspb.Message.getField(this, 5) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.danielvladco.protobuf.graphql.Field.prototype.toObject = function (opt_includeInstance) {
    return proto.danielvladco.protobuf.graphql.Field.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.danielvladco.protobuf.graphql.Field} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.danielvladco.protobuf.graphql.Field.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        required: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
        params: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        dirs: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        ignore: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
        name: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.danielvladco.protobuf.graphql.Field}
 */
proto.danielvladco.protobuf.graphql.Field.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.danielvladco.protobuf.graphql.Field();
  return proto.danielvladco.protobuf.graphql.Field.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.danielvladco.protobuf.graphql.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.danielvladco.protobuf.graphql.Field}
 */
proto.danielvladco.protobuf.graphql.Field.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */reader.readBool();
        msg.setRequired(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setParams(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setDirs(value);
        break;
      case 4:
        var value = /** @type {boolean} */reader.readBool();
        msg.setIgnore(value);
        break;
      case 5:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.danielvladco.protobuf.graphql.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.danielvladco.protobuf.graphql.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.danielvladco.protobuf.graphql.Field.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeBool(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeBool(4, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeString(5, f);
  }
};

/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.getRequired = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 1, false);
};

/**
 * @param {boolean} value
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.setRequired = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.clearRequired = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.hasRequired = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string params = 2;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.getParams = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.setParams = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.clearParams = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.hasParams = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string dirs = 3;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.getDirs = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.setDirs = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.clearDirs = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.hasDirs = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool ignore = 4;
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.getIgnore = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 4, false);
};

/**
 * @param {boolean} value
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.setIgnore = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.clearIgnore = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.hasIgnore = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string name = 5;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 5, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.setName = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Field} returns this
 */
proto.danielvladco.protobuf.graphql.Field.prototype.clearName = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Field.prototype.hasName = function () {
  return jspb.Message.getField(this, 5) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.danielvladco.protobuf.graphql.Rpc.prototype.toObject = function (opt_includeInstance) {
    return proto.danielvladco.protobuf.graphql.Rpc.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.danielvladco.protobuf.graphql.Rpc} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.danielvladco.protobuf.graphql.Rpc.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        ignore: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.danielvladco.protobuf.graphql.Rpc}
 */
proto.danielvladco.protobuf.graphql.Rpc.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.danielvladco.protobuf.graphql.Rpc();
  return proto.danielvladco.protobuf.graphql.Rpc.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.danielvladco.protobuf.graphql.Rpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.danielvladco.protobuf.graphql.Rpc}
 */
proto.danielvladco.protobuf.graphql.Rpc.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.danielvladco.protobuf.graphql.Type} */reader.readEnum();
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setIgnore(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.danielvladco.protobuf.graphql.Rpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.danielvladco.protobuf.graphql.Rpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.danielvladco.protobuf.graphql.Rpc.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.danielvladco.protobuf.graphql.Type} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
};

/**
 * optional Type type = 1;
 * @return {!proto.danielvladco.protobuf.graphql.Type}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.getType = function () {
  return /** @type {!proto.danielvladco.protobuf.graphql.Type} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {!proto.danielvladco.protobuf.graphql.Type} value
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.setType = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.clearType = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.hasType = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool ignore = 2;
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.getIgnore = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.setIgnore = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.clearIgnore = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.hasIgnore = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.setName = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Rpc} returns this
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.clearName = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Rpc.prototype.hasName = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.danielvladco.protobuf.graphql.Svc.prototype.toObject = function (opt_includeInstance) {
    return proto.danielvladco.protobuf.graphql.Svc.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.danielvladco.protobuf.graphql.Svc} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.danielvladco.protobuf.graphql.Svc.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        ignore: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        upstream: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.danielvladco.protobuf.graphql.Svc}
 */
proto.danielvladco.protobuf.graphql.Svc.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.danielvladco.protobuf.graphql.Svc();
  return proto.danielvladco.protobuf.graphql.Svc.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.danielvladco.protobuf.graphql.Svc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.danielvladco.protobuf.graphql.Svc}
 */
proto.danielvladco.protobuf.graphql.Svc.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.danielvladco.protobuf.graphql.Type} */reader.readEnum();
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setIgnore(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {!proto.danielvladco.protobuf.graphql.Upstream} */reader.readEnum();
        msg.setUpstream(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.danielvladco.protobuf.graphql.Svc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.danielvladco.protobuf.graphql.Svc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.danielvladco.protobuf.graphql.Svc.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.danielvladco.protobuf.graphql.Type} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {!proto.danielvladco.protobuf.graphql.Upstream} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional Type type = 1;
 * @return {!proto.danielvladco.protobuf.graphql.Type}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.getType = function () {
  return /** @type {!proto.danielvladco.protobuf.graphql.Type} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {!proto.danielvladco.protobuf.graphql.Type} value
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.setType = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.clearType = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.hasType = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool ignore = 2;
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.getIgnore = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.setIgnore = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.clearIgnore = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.hasIgnore = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.setName = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.clearName = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.hasName = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Upstream upstream = 4;
 * @return {!proto.danielvladco.protobuf.graphql.Upstream}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.getUpstream = function () {
  return /** @type {!proto.danielvladco.protobuf.graphql.Upstream} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {!proto.danielvladco.protobuf.graphql.Upstream} value
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.setUpstream = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.danielvladco.protobuf.graphql.Svc} returns this
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.clearUpstream = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.danielvladco.protobuf.graphql.Svc.prototype.hasUpstream = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * @enum {number}
 */
proto.danielvladco.protobuf.graphql.Type = {
  DEFAULT: 0,
  MUTATION: 1,
  QUERY: 2
};

/**
 * @enum {number}
 */
proto.danielvladco.protobuf.graphql.Upstream = {
  UPSTREAM_UNSPECIFIED: 0,
  UPSTREAM_SERVER: 1,
  UPSTREAM_CLIENT: 2
};

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `rpc`.
 * @type {!jspb.ExtensionFieldInfo<!proto.danielvladco.protobuf.graphql.Rpc>}
 */
proto.danielvladco.protobuf.graphql.rpc = new jspb.ExtensionFieldInfo(65030, {
  rpc: 0
}, proto.danielvladco.protobuf.graphql.Rpc, /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */
proto.danielvladco.protobuf.graphql.Rpc.toObject, 0);
google_protobuf_descriptor_pb.MethodOptions.extensionsBinary[65030] = new jspb.ExtensionFieldBinaryInfo(proto.danielvladco.protobuf.graphql.rpc, jspb.BinaryReader.prototype.readMessage, jspb.BinaryWriter.prototype.writeMessage, proto.danielvladco.protobuf.graphql.Rpc.serializeBinaryToWriter, proto.danielvladco.protobuf.graphql.Rpc.deserializeBinaryFromReader, false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MethodOptions.extensions[65030] = proto.danielvladco.protobuf.graphql.rpc;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `svc`.
 * @type {!jspb.ExtensionFieldInfo<!proto.danielvladco.protobuf.graphql.Svc>}
 */
proto.danielvladco.protobuf.graphql.svc = new jspb.ExtensionFieldInfo(65030, {
  svc: 0
}, proto.danielvladco.protobuf.graphql.Svc, /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */
proto.danielvladco.protobuf.graphql.Svc.toObject, 0);
google_protobuf_descriptor_pb.ServiceOptions.extensionsBinary[65030] = new jspb.ExtensionFieldBinaryInfo(proto.danielvladco.protobuf.graphql.svc, jspb.BinaryReader.prototype.readMessage, jspb.BinaryWriter.prototype.writeMessage, proto.danielvladco.protobuf.graphql.Svc.serializeBinaryToWriter, proto.danielvladco.protobuf.graphql.Svc.deserializeBinaryFromReader, false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.ServiceOptions.extensions[65030] = proto.danielvladco.protobuf.graphql.svc;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `field`.
 * @type {!jspb.ExtensionFieldInfo<!proto.danielvladco.protobuf.graphql.Field>}
 */
proto.danielvladco.protobuf.graphql.field = new jspb.ExtensionFieldInfo(65030, {
  field: 0
}, proto.danielvladco.protobuf.graphql.Field, /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */
proto.danielvladco.protobuf.graphql.Field.toObject, 0);
google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[65030] = new jspb.ExtensionFieldBinaryInfo(proto.danielvladco.protobuf.graphql.field, jspb.BinaryReader.prototype.readMessage, jspb.BinaryWriter.prototype.writeMessage, proto.danielvladco.protobuf.graphql.Field.serializeBinaryToWriter, proto.danielvladco.protobuf.graphql.Field.deserializeBinaryFromReader, false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[65030] = proto.danielvladco.protobuf.graphql.field;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `oneof`.
 * @type {!jspb.ExtensionFieldInfo<!proto.danielvladco.protobuf.graphql.Oneof>}
 */
proto.danielvladco.protobuf.graphql.oneof = new jspb.ExtensionFieldInfo(65030, {
  oneof: 0
}, proto.danielvladco.protobuf.graphql.Oneof, /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */
proto.danielvladco.protobuf.graphql.Oneof.toObject, 0);
google_protobuf_descriptor_pb.OneofOptions.extensionsBinary[65030] = new jspb.ExtensionFieldBinaryInfo(proto.danielvladco.protobuf.graphql.oneof, jspb.BinaryReader.prototype.readMessage, jspb.BinaryWriter.prototype.writeMessage, proto.danielvladco.protobuf.graphql.Oneof.serializeBinaryToWriter, proto.danielvladco.protobuf.graphql.Oneof.deserializeBinaryFromReader, false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.OneofOptions.extensions[65030] = proto.danielvladco.protobuf.graphql.oneof;
goog.object.extend(exports, proto.danielvladco.protobuf.graphql);