// source: auth_svc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
  return this;
}.call(null) || Function('return this')();
var danielvladco_protobuf_graphql_pb = require('./danielvladco/protobuf/graphql_pb.js');
goog.object.extend(proto, danielvladco_protobuf_graphql_pb);
goog.exportSymbol('proto.auth_svc.GoogleUserInfo', null, global);
goog.exportSymbol('proto.auth_svc.HealthCheckRequest', null, global);
goog.exportSymbol('proto.auth_svc.HealthCheckResponse', null, global);
goog.exportSymbol('proto.auth_svc.OauthGoogleCallbackRequest', null, global);
goog.exportSymbol('proto.auth_svc.OauthGoogleCallbackResponse', null, global);
goog.exportSymbol('proto.auth_svc.OauthGoogleSignInRequest', null, global);
goog.exportSymbol('proto.auth_svc.OauthGoogleSignInResponse', null, global);
goog.exportSymbol('proto.auth_svc.SessionCheckRequest', null, global);
goog.exportSymbol('proto.auth_svc.SessionCheckResponse', null, global);
goog.exportSymbol('proto.auth_svc.User', null, global);
goog.exportSymbol('proto.auth_svc.WalletSignInFinalizeRequest', null, global);
goog.exportSymbol('proto.auth_svc.WalletSignInFinalizeResponse', null, global);
goog.exportSymbol('proto.auth_svc.WalletSignInStartRequest', null, global);
goog.exportSymbol('proto.auth_svc.WalletSignInStartResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.WalletSignInStartRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.WalletSignInStartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.WalletSignInStartRequest.displayName = 'proto.auth_svc.WalletSignInStartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.WalletSignInStartResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.WalletSignInStartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.WalletSignInStartResponse.displayName = 'proto.auth_svc.WalletSignInStartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.WalletSignInFinalizeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.WalletSignInFinalizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.WalletSignInFinalizeRequest.displayName = 'proto.auth_svc.WalletSignInFinalizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.WalletSignInFinalizeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.WalletSignInFinalizeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.WalletSignInFinalizeResponse.displayName = 'proto.auth_svc.WalletSignInFinalizeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.SessionCheckRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.SessionCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.SessionCheckRequest.displayName = 'proto.auth_svc.SessionCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.SessionCheckResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.SessionCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.SessionCheckResponse.displayName = 'proto.auth_svc.SessionCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.User = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.User.displayName = 'proto.auth_svc.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.OauthGoogleSignInRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.OauthGoogleSignInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.OauthGoogleSignInRequest.displayName = 'proto.auth_svc.OauthGoogleSignInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.OauthGoogleSignInResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.OauthGoogleSignInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.OauthGoogleSignInResponse.displayName = 'proto.auth_svc.OauthGoogleSignInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.OauthGoogleCallbackRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.OauthGoogleCallbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.OauthGoogleCallbackRequest.displayName = 'proto.auth_svc.OauthGoogleCallbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.OauthGoogleCallbackResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.OauthGoogleCallbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.OauthGoogleCallbackResponse.displayName = 'proto.auth_svc.OauthGoogleCallbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.GoogleUserInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.GoogleUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.GoogleUserInfo.displayName = 'proto.auth_svc.GoogleUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.HealthCheckRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.HealthCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.HealthCheckRequest.displayName = 'proto.auth_svc.HealthCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth_svc.HealthCheckResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth_svc.HealthCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth_svc.HealthCheckResponse.displayName = 'proto.auth_svc.HealthCheckResponse';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.WalletSignInStartRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.WalletSignInStartRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.WalletSignInStartRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.WalletSignInStartRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        schema: jspb.Message.getFieldWithDefault(msg, 1, ""),
        address: jspb.Message.getFieldWithDefault(msg, 2, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.WalletSignInStartRequest}
 */
proto.auth_svc.WalletSignInStartRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.WalletSignInStartRequest();
  return proto.auth_svc.WalletSignInStartRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.WalletSignInStartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.WalletSignInStartRequest}
 */
proto.auth_svc.WalletSignInStartRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setSchema(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setAddress(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.WalletSignInStartRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.WalletSignInStartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.WalletSignInStartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.WalletSignInStartRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string schema = 1;
 * @return {string}
 */
proto.auth_svc.WalletSignInStartRequest.prototype.getSchema = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInStartRequest} returns this
 */
proto.auth_svc.WalletSignInStartRequest.prototype.setSchema = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string address = 2;
 * @return {string}
 */
proto.auth_svc.WalletSignInStartRequest.prototype.getAddress = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInStartRequest} returns this
 */
proto.auth_svc.WalletSignInStartRequest.prototype.setAddress = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.WalletSignInStartResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.WalletSignInStartResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.WalletSignInStartResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.WalletSignInStartResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
        digest: jspb.Message.getFieldWithDefault(msg, 2, ""),
        data: jspb.Message.getFieldWithDefault(msg, 3, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.WalletSignInStartResponse}
 */
proto.auth_svc.WalletSignInStartResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.WalletSignInStartResponse();
  return proto.auth_svc.WalletSignInStartResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.WalletSignInStartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.WalletSignInStartResponse}
 */
proto.auth_svc.WalletSignInStartResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */reader.readSint64();
        msg.setTimestamp(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setDigest(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.WalletSignInStartResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.WalletSignInStartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.WalletSignInStartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.WalletSignInStartResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeSint64(1, f);
  }
  f = message.getDigest();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional sint64 timestamp = 1;
 * @return {number}
 */
proto.auth_svc.WalletSignInStartResponse.prototype.getTimestamp = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {number} value
 * @return {!proto.auth_svc.WalletSignInStartResponse} returns this
 */
proto.auth_svc.WalletSignInStartResponse.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string digest = 2;
 * @return {string}
 */
proto.auth_svc.WalletSignInStartResponse.prototype.getDigest = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInStartResponse} returns this
 */
proto.auth_svc.WalletSignInStartResponse.prototype.setDigest = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string data = 3;
 * @return {string}
 */
proto.auth_svc.WalletSignInStartResponse.prototype.getData = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInStartResponse} returns this
 */
proto.auth_svc.WalletSignInStartResponse.prototype.setData = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.WalletSignInFinalizeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.WalletSignInFinalizeRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.WalletSignInFinalizeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.WalletSignInFinalizeRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        schema: jspb.Message.getFieldWithDefault(msg, 1, ""),
        timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
        digest: jspb.Message.getFieldWithDefault(msg, 3, ""),
        signature: jspb.Message.getFieldWithDefault(msg, 4, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest}
 */
proto.auth_svc.WalletSignInFinalizeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.WalletSignInFinalizeRequest();
  return proto.auth_svc.WalletSignInFinalizeRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.WalletSignInFinalizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest}
 */
proto.auth_svc.WalletSignInFinalizeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setSchema(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readSint64();
        msg.setTimestamp(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setDigest(value);
        break;
      case 4:
        var value = /** @type {string} */reader.readString();
        msg.setSignature(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.WalletSignInFinalizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.WalletSignInFinalizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.WalletSignInFinalizeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeSint64(2, f);
  }
  f = message.getDigest();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string schema = 1;
 * @return {string}
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.getSchema = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest} returns this
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.setSchema = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional sint64 timestamp = 2;
 * @return {number}
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.getTimestamp = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest} returns this
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string digest = 3;
 * @return {string}
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.getDigest = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest} returns this
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.setDigest = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string signature = 4;
 * @return {string}
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.getSignature = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 4, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInFinalizeRequest} returns this
 */
proto.auth_svc.WalletSignInFinalizeRequest.prototype.setSignature = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.WalletSignInFinalizeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.WalletSignInFinalizeResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.WalletSignInFinalizeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.WalletSignInFinalizeResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
        expired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.WalletSignInFinalizeResponse}
 */
proto.auth_svc.WalletSignInFinalizeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.WalletSignInFinalizeResponse();
  return proto.auth_svc.WalletSignInFinalizeResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.WalletSignInFinalizeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.WalletSignInFinalizeResponse}
 */
proto.auth_svc.WalletSignInFinalizeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setJwt(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setExpired(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.WalletSignInFinalizeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.WalletSignInFinalizeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.WalletSignInFinalizeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.WalletSignInFinalizeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.auth_svc.WalletSignInFinalizeResponse.prototype.getJwt = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.WalletSignInFinalizeResponse} returns this
 */
proto.auth_svc.WalletSignInFinalizeResponse.prototype.setJwt = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool expired = 2;
 * @return {boolean}
 */
proto.auth_svc.WalletSignInFinalizeResponse.prototype.getExpired = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.auth_svc.WalletSignInFinalizeResponse} returns this
 */
proto.auth_svc.WalletSignInFinalizeResponse.prototype.setExpired = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.SessionCheckRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.SessionCheckRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.SessionCheckRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.SessionCheckRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {};
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.SessionCheckRequest}
 */
proto.auth_svc.SessionCheckRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.SessionCheckRequest();
  return proto.auth_svc.SessionCheckRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.SessionCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.SessionCheckRequest}
 */
proto.auth_svc.SessionCheckRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.SessionCheckRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.SessionCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.SessionCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.SessionCheckRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.SessionCheckResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.SessionCheckResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.SessionCheckResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.SessionCheckResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        status: jspb.Message.getFieldWithDefault(msg, 1, ""),
        user: (f = msg.getUser()) && proto.auth_svc.User.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.SessionCheckResponse}
 */
proto.auth_svc.SessionCheckResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.SessionCheckResponse();
  return proto.auth_svc.SessionCheckResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.SessionCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.SessionCheckResponse}
 */
proto.auth_svc.SessionCheckResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setStatus(value);
        break;
      case 2:
        var value = new proto.auth_svc.User();
        reader.readMessage(value, proto.auth_svc.User.deserializeBinaryFromReader);
        msg.setUser(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.SessionCheckResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.SessionCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.SessionCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.SessionCheckResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(2, f, proto.auth_svc.User.serializeBinaryToWriter);
  }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.auth_svc.SessionCheckResponse.prototype.getStatus = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.SessionCheckResponse} returns this
 */
proto.auth_svc.SessionCheckResponse.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional User user = 2;
 * @return {?proto.auth_svc.User}
 */
proto.auth_svc.SessionCheckResponse.prototype.getUser = function () {
  return /** @type{?proto.auth_svc.User} */jspb.Message.getWrapperField(this, proto.auth_svc.User, 2);
};

/**
 * @param {?proto.auth_svc.User|undefined} value
 * @return {!proto.auth_svc.SessionCheckResponse} returns this
*/
proto.auth_svc.SessionCheckResponse.prototype.setUser = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.auth_svc.SessionCheckResponse} returns this
 */
proto.auth_svc.SessionCheckResponse.prototype.clearUser = function () {
  return this.setUser(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth_svc.SessionCheckResponse.prototype.hasUser = function () {
  return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.User.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.User.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.User} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.User.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        walletAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
        avatar: jspb.Message.getFieldWithDefault(msg, 4, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.User}
 */
proto.auth_svc.User.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.User();
  return proto.auth_svc.User.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.User}
 */
proto.auth_svc.User.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setWalletAddress(value);
        break;
      case 4:
        var value = /** @type {string} */reader.readString();
        msg.setAvatar(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.User.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.User.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getWalletAddress();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.auth_svc.User.prototype.getId = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.User} returns this
 */
proto.auth_svc.User.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.auth_svc.User.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.User} returns this
 */
proto.auth_svc.User.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string wallet_address = 3;
 * @return {string}
 */
proto.auth_svc.User.prototype.getWalletAddress = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.User} returns this
 */
proto.auth_svc.User.prototype.setWalletAddress = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string avatar = 4;
 * @return {string}
 */
proto.auth_svc.User.prototype.getAvatar = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 4, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.User} returns this
 */
proto.auth_svc.User.prototype.setAvatar = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.OauthGoogleSignInRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.OauthGoogleSignInRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.OauthGoogleSignInRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.OauthGoogleSignInRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {};
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.OauthGoogleSignInRequest}
 */
proto.auth_svc.OauthGoogleSignInRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.OauthGoogleSignInRequest();
  return proto.auth_svc.OauthGoogleSignInRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.OauthGoogleSignInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.OauthGoogleSignInRequest}
 */
proto.auth_svc.OauthGoogleSignInRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.OauthGoogleSignInRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.OauthGoogleSignInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.OauthGoogleSignInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.OauthGoogleSignInRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.OauthGoogleSignInResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.OauthGoogleSignInResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.OauthGoogleSignInResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.OauthGoogleSignInResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.OauthGoogleSignInResponse}
 */
proto.auth_svc.OauthGoogleSignInResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.OauthGoogleSignInResponse();
  return proto.auth_svc.OauthGoogleSignInResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.OauthGoogleSignInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.OauthGoogleSignInResponse}
 */
proto.auth_svc.OauthGoogleSignInResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setRedirectUrl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.OauthGoogleSignInResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.OauthGoogleSignInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.OauthGoogleSignInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.OauthGoogleSignInResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.auth_svc.OauthGoogleSignInResponse.prototype.getRedirectUrl = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.OauthGoogleSignInResponse} returns this
 */
proto.auth_svc.OauthGoogleSignInResponse.prototype.setRedirectUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.OauthGoogleCallbackRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.OauthGoogleCallbackRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.OauthGoogleCallbackRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.OauthGoogleCallbackRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ""),
        state: jspb.Message.getFieldWithDefault(msg, 2, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.OauthGoogleCallbackRequest}
 */
proto.auth_svc.OauthGoogleCallbackRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.OauthGoogleCallbackRequest();
  return proto.auth_svc.OauthGoogleCallbackRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.OauthGoogleCallbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.OauthGoogleCallbackRequest}
 */
proto.auth_svc.OauthGoogleCallbackRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setState(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.OauthGoogleCallbackRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.OauthGoogleCallbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.OauthGoogleCallbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.OauthGoogleCallbackRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string code = 1;
 * @return {string}
 */
proto.auth_svc.OauthGoogleCallbackRequest.prototype.getCode = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.OauthGoogleCallbackRequest} returns this
 */
proto.auth_svc.OauthGoogleCallbackRequest.prototype.setCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string state = 2;
 * @return {string}
 */
proto.auth_svc.OauthGoogleCallbackRequest.prototype.getState = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.OauthGoogleCallbackRequest} returns this
 */
proto.auth_svc.OauthGoogleCallbackRequest.prototype.setState = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.OauthGoogleCallbackResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.OauthGoogleCallbackResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.OauthGoogleCallbackResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.OauthGoogleCallbackResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
        expired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        userInfo: (f = msg.getUserInfo()) && proto.auth_svc.GoogleUserInfo.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse}
 */
proto.auth_svc.OauthGoogleCallbackResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.OauthGoogleCallbackResponse();
  return proto.auth_svc.OauthGoogleCallbackResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.OauthGoogleCallbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse}
 */
proto.auth_svc.OauthGoogleCallbackResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setJwt(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setExpired(value);
        break;
      case 3:
        var value = new proto.auth_svc.GoogleUserInfo();
        reader.readMessage(value, proto.auth_svc.GoogleUserInfo.deserializeBinaryFromReader);
        msg.setUserInfo(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.OauthGoogleCallbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.OauthGoogleCallbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.OauthGoogleCallbackResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(3, f, proto.auth_svc.GoogleUserInfo.serializeBinaryToWriter);
  }
};

/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.getJwt = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse} returns this
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.setJwt = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool expired = 2;
 * @return {boolean}
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.getExpired = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse} returns this
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.setExpired = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional GoogleUserInfo user_info = 3;
 * @return {?proto.auth_svc.GoogleUserInfo}
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.getUserInfo = function () {
  return /** @type{?proto.auth_svc.GoogleUserInfo} */jspb.Message.getWrapperField(this, proto.auth_svc.GoogleUserInfo, 3);
};

/**
 * @param {?proto.auth_svc.GoogleUserInfo|undefined} value
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse} returns this
*/
proto.auth_svc.OauthGoogleCallbackResponse.prototype.setUserInfo = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.auth_svc.OauthGoogleCallbackResponse} returns this
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.clearUserInfo = function () {
  return this.setUserInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth_svc.OauthGoogleCallbackResponse.prototype.hasUserInfo = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.GoogleUserInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.GoogleUserInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.GoogleUserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.GoogleUserInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        email: jspb.Message.getFieldWithDefault(msg, 2, ""),
        name: jspb.Message.getFieldWithDefault(msg, 3, "")
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.GoogleUserInfo}
 */
proto.auth_svc.GoogleUserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.GoogleUserInfo();
  return proto.auth_svc.GoogleUserInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.GoogleUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.GoogleUserInfo}
 */
proto.auth_svc.GoogleUserInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setEmail(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.GoogleUserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.GoogleUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.GoogleUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.GoogleUserInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.auth_svc.GoogleUserInfo.prototype.getId = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.GoogleUserInfo} returns this
 */
proto.auth_svc.GoogleUserInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string email = 2;
 * @return {string}
 */
proto.auth_svc.GoogleUserInfo.prototype.getEmail = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.GoogleUserInfo} returns this
 */
proto.auth_svc.GoogleUserInfo.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.auth_svc.GoogleUserInfo.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.GoogleUserInfo} returns this
 */
proto.auth_svc.GoogleUserInfo.prototype.setName = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.auth_svc.GoogleUserInfo} returns this
 */
proto.auth_svc.GoogleUserInfo.prototype.clearName = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth_svc.GoogleUserInfo.prototype.hasName = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.HealthCheckRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.HealthCheckRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.HealthCheckRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.HealthCheckRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {};
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.HealthCheckRequest}
 */
proto.auth_svc.HealthCheckRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.HealthCheckRequest();
  return proto.auth_svc.HealthCheckRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.HealthCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.HealthCheckRequest}
 */
proto.auth_svc.HealthCheckRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.HealthCheckRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.HealthCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.HealthCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.HealthCheckRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.auth_svc.HealthCheckResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.auth_svc.HealthCheckResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.auth_svc.HealthCheckResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.auth_svc.HealthCheckResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uptime: jspb.Message.getFieldWithDefault(msg, 1, ""),
        uptimeSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth_svc.HealthCheckResponse}
 */
proto.auth_svc.HealthCheckResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth_svc.HealthCheckResponse();
  return proto.auth_svc.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth_svc.HealthCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth_svc.HealthCheckResponse}
 */
proto.auth_svc.HealthCheckResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setUptime(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readSint32();
        msg.setUptimeSeconds(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth_svc.HealthCheckResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.auth_svc.HealthCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth_svc.HealthCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth_svc.HealthCheckResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUptime();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUptimeSeconds();
  if (f !== 0) {
    writer.writeSint32(2, f);
  }
};

/**
 * optional string uptime = 1;
 * @return {string}
 */
proto.auth_svc.HealthCheckResponse.prototype.getUptime = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.auth_svc.HealthCheckResponse} returns this
 */
proto.auth_svc.HealthCheckResponse.prototype.setUptime = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional sint32 uptime_seconds = 2;
 * @return {number}
 */
proto.auth_svc.HealthCheckResponse.prototype.getUptimeSeconds = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.auth_svc.HealthCheckResponse} returns this
 */
proto.auth_svc.HealthCheckResponse.prototype.setUptimeSeconds = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
goog.object.extend(exports, proto.auth_svc);